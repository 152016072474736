import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import { realisationGallery } from "./gallery";
import { scrollAjax } from "./ajaxPosts";
import { isotope } from "./isotope";

window.addEventListener("load", () => {
  let sitePadding = document
    .querySelector("body")
    .classList.contains("page-template-homepage")
    ? false
    : true;

  new SiteHeader({
    setBodyPaddingTop: sitePadding
  });
  new FloatLabels();

  scrollAjax();
  toggleMenu();
  realisationGallery(document.getElementById("realisation-gallery"));
  isotope();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
  isotope();
};
