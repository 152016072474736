export const realisationGallery = container => {
  if (container) {
    let $thumbs = container.querySelectorAll(
      ".realisation__gallery-thumbs .thumb"
    );
    let $bigImageContainer = container.querySelector(
      ".realisation__gallery-image-big"
    );

    $thumbs.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();
        let bigImageUrl = el.getAttribute("data-image-large");
        $bigImageContainer.setAttribute("src", bigImageUrl);
      });
    });
  }
};
