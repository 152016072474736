import $ from "jquery";
import { isotope } from "./isotope";

export const scrollAjax = () => {
  let $container = $(".isotope-container"),
    currentPageCounter = 1,
    loader = $(".spinner"),
    maxPaged = jsData.maxPaged,
    scrolled = true,
    pagedCounter = 2;

  $(window).scroll(function() {
    if (pagedCounter < maxPaged && scrolled) {
      $.ajax({
        type: "POST",
        url: jsData.ajaxUrl,
        data: {
          action: "get_ajax_posts",
          currentPage: currentPageCounter,
          pagedCounter: pagedCounter
        },
        beforeSend: function() {
          scrolled = false;
          loader.show();
        },
        success: function(response) {
          // console.log(response);
          if (response) {
            $container.append(response);
            currentPageCounter++;
            loader.hide();
            pagedCounter < maxPaged ? pagedCounter++ : null;
            isotope();
          }
        },
        complete: function() {
          scrolled = true;
        },
        error: function(error) {
          console.log(error);
        }
      });
    }
  });
};
