// https://github.com/kenwheeler/slick

import $ from "jquery";
import { pad } from "./helper";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $("#home__slider"),
  offer: $("#offer-slider")
};

sliders.home.on("init", function() {
  $(".slick-dots li:first-child").addClass("active");
});

sliders.home.on("beforeChange", function(
  event,
  slick,
  currentSlide,
  nextSlide
) {
  let dots = $(".slick-dots li");
  dots.removeClass("active");
  sliders.home
    .find(`.slick-dots li button[data-slide-index=${nextSlide}]`)
    .parent()
    .addClass("active");
});

sliders.home.slick({
  autoplay: true,
  dots: false,
  arrows: false,
  dots: true,
  customPaging: function(slider, i) {
    return `<button data-slide-index=${i}><span>${pad(i + 1)}</span></button>`;
  },
  pauseOnFocus: false,
  pauseOnHover: false,
  speed: 2000,
  fade: true,
  autoplay: true,
  appendDots: $(".home__slider-dots")
});

sliders.offer.slick({
  autoplay: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  speed: 1500
});
