import Isotope from "isotope-layout";

export const isotope = () => {
  let grid = document.querySelector(".isotope-container");
  if (grid) {
    new Isotope(grid, {
      itemSelector: ".realisation",
      gutter: 10
    });
  }
};
